<template>
    <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <div class="login-container" v-show="!casting">
          <h5 class="text-gray-100 text-center mb-3">Sign up</h5>
          <form class="mb-2" @submit.prevent="sign_up()">
            <b-input v-model="nick_name"  class="mb-3" type="text" placeholder="Nickname" />
            <b-input v-model="email"  class="mb-3" type="email" placeholder="Email" />
            <b-input v-model="password"  class="mb-3" type="password" placeholder="Password" />
            <b-input v-model="password_repeat"  class="mb-3" type="password" placeholder="Repeat password" />

            <b-btn :disabled="loading" class="w-100 rounded-pill button-gradient-primary mb-3" type="submit">
              <span v-if="loading">
                <b-spinner style="height: 16px; width: 16px;"  label="Spinning" />
              </span>

              <span v-if="!loading">Sign up</span>
            </b-btn>
            <b-btn :disabled="loading" class="w-100 rounded-pill button-outline-primary" @click="casting = true">
              Casting
              <b-icon color="green" v-if="casting_success" icon="check" />
            </b-btn>
          </form>
          <router-link to="/login" class="small link-gray-200 ml-1">
            Login
          </router-link>
        </div>

        <casting-component v-if="casting" @back="casting = false" @submit="() => {casting = false; casting_success = true}" />
  </div>
</template>

<script>
export default {
  data: () => ({
    email: '',
    nick_name: '',
    password: '',
    password_repeat: '',
    loading: false,
    casting: false,
    casting_success: false,
  }),

  mounted() {
    if(this.$route.query.group ) {
      localStorage.setItem('tigpogGroup', this.$route.query.group)
    }
  },

  methods: {
    sign_up(){
      const group = this.$route.query.group || localStorage.getItem('tigpogGroup');

      this.loading = true
      var credentials = {
        email: this.email,
        nick_name: this.nick_name,
        password: this.password,
        casting: this.casting_success
      }

      if(this.password_repeat === this.password) {
        this.$store.dispatch("SIGN_UP", {
          credentials: credentials,
          group: group
        })
            .then(() => {
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              if(error.response.status === 400) {
                this.$bvToast.toast(error.response.data.detail, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  variant: 'danger'
                })
              }
            })
      } else {
        alert('Passwords do not match')
      }
    }
  }
}
</script>